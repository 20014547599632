import storage from 'redux-persist/lib/storage';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
import { BRASS_APP_REDUX_PERSIST_KEY } from 'src/data/env';
import {
  REDUX_PERSIST_THROTTLE,
  REDUX_PERSIST_VERSION,
} from 'src/data/constants';

export const REDUX_PERSIST_CONFIG = {
  key: BRASS_APP_REDUX_PERSIST_KEY,
  storage,
  version: REDUX_PERSIST_VERSION,
  stateReconciler: autoMergeLevel2,
  throttle: REDUX_PERSIST_THROTTLE,
  debug: process.env.NODE_ENV === 'development',
  whitelist: ['module/auth', 'module/common'],
};
