import 'src/helpers/wdyr'; //why-did-you-render
import React from 'react';
import ReactDOM from 'react-dom/client';
import * as Sentry from '@sentry/react';
import App from 'src/app/App';
// import reportWebVitals from 'src/utils/performanceUtils';
import { SENTRY_CONFIG } from 'src/config/sentry';
import PageLoader from './sharedComponents/PageLoader';

Sentry.init(SENTRY_CONFIG);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);
root.render(
  <React.StrictMode>
    <React.Suspense fallback={<PageLoader />}>
      <App />
    </React.Suspense>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals(console.log);
