export const ENV: string = process.env.NODE_ENV!;

export const API_URL: string = process.env.REACT_APP_API_URL!;

export const API_KEY: string = process.env.REACT_APP_API_KEY!;

export const COOKIE_DOMAIN = process.env.REACT_APP_COOKIES_DOMAIN!;

export const SENTRY_KEY = process.env.REACT_APP_SENTRY_PUBLIC_KEY;

export const BRASS_APP_REDUX_PERSIST_KEY =
  process.env.REACT_APP_BRASS_APP_REDUX_PERSIST_KEY! || 'DEMO_KEY';

export const BRASS_HOMEPAGE_URL: string =
  process.env.REACT_APP_BRASS_HOMEPAGE_URL!;

export const BRASS_APP_URL: string = process.env.REACT_APP_BRASS_APP_URL!;

export const BRASS_PRIVACY_POLICY: string =
  process.env.REACT_APP_BRASS_PRIVACY_POLICY!;

export const BRASS_TERMS_CONDITIONS: string =
  process.env.REACT_APP_BRASS_TERMS_CONDITIONS!;

export const BRASS_CAPITAL_TERMS_CONDITIONS: string =
  process.env.REACT_APP_BRASS_HOMEPAGE_URL!;

export const BRASS_HELP: string = process.env.REACT_APP_BRASS_HELP!;

export const BRASS_CDN_URL: string = process.env.REACT_APP_BRASS_CDN_URL!;

export const SENTRY_TRACES_SAMPLE_RATE = process.env
  ?.REACT_APP_SENTRY_TRACES_SAMPLE_RATE
  ? Number(process.env?.REACT_APP_SENTRY_TRACES_SAMPLE_RATE)
  : 0;

export const SENTRY_ENV = process.env?.REACT_APP_SENTRY_ENV || ENV;

export const PAYSTACK_API_KEY: string = process.env.REACT_APP_PAYSTACK_API_KEY!;

export const PAYSTACK_INLINE_SCRIPT_URL: string =
  process.env.REACT_APP_PAYSTACK_INLINE_SCRIPT_URL!;

export const FLUTTERWAVE_KE_API_KEY: string =
  process.env.REACT_APP_FLUTTERWAVE_KE_API_KEY!;

export const FLUTTERWAVE_INLINE_SCRIPT_URL: string =
  process.env.REACT_APP_FLUTTERWAVE_INLINE_SCRIPT_URL!;

export const TINGG_INLINE_SCRIPT_URL: string =
  process.env.REACT_APP_TINGG_INLINE_SCRIPT_URL!;
