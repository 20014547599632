import React from 'react';
import { RouteObject, useRoutes, Navigate } from 'react-router-dom';
import {
  notFoundPagePath,
  paymentPageSlug,
  appRootPath,
} from 'src/data/pageRoutes';
import Payment from 'src/modules/payment';
import PageLoader from 'src/sharedComponents/PageLoader';
import PageNotFound from 'src/sharedComponents/PageNotFound';

const AppRouter = () => {
  return useRoutes([
    {
      path: `${paymentPageSlug}/:accountId`,
      children: [
        {
          index: true,
          element: (
            <React.Suspense fallback={<PageLoader />}>
              <Payment />
            </React.Suspense>
          ),
        },
        {
          path: ':token',
          element: (
            <React.Suspense fallback={<PageLoader />}>
              <Payment />
            </React.Suspense>
          ),
        },
      ],
    },
    { path: notFoundPagePath, element: <PageNotFound /> },
    { path: '/*', element: <Navigate to={notFoundPagePath} replace /> },
    { path: appRootPath, element: <Navigate to={notFoundPagePath} replace /> },
  ] as RouteObject[]);
};

export default AppRouter;
