import React from 'react';
import { ToastContainer } from 'brass-ui-kit';
import { withErrorBoundary } from '@sentry/react';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';
import AppRouter from './AppRouter';
import PageLoader from 'src/sharedComponents/PageLoader';
import AppInitializer from './AppInitializer';

import { store, persistor } from 'src/redux/store';

import 'src/styles/main.scss';
import 'brass-ui-kit/dist/styles/brass-dark.css';

const App: React.FC = () => {
  return (
    <BrowserRouter>
      <Provider store={store}>
        <PersistGate loading={<PageLoader />} persistor={persistor}>
          <AppInitializer>
            <AppRouter />
            <ToastContainer />
          </AppInitializer>
        </PersistGate>
      </Provider>
    </BrowserRouter>
  );
};

export default withErrorBoundary(App, {
  fallback: <h1>An error has occurred</h1>,
});
