import {
  CellulantLogo,
  FlutterwaveCircleLogo,
  PaystackCircleLogo,
} from 'src/assets/svgs';
import { Currency, PaymentProvider } from 'src/types/generics';
import {
  FLUTTERWAVE_INLINE_SCRIPT_URL,
  FLUTTERWAVE_KE_API_KEY,
  PAYSTACK_API_KEY,
  PAYSTACK_INLINE_SCRIPT_URL,
  TINGG_INLINE_SCRIPT_URL,
} from './env';

export const REDUX_PERSIST_VERSION = 1;

export const REDUX_PERSIST_THROTTLE = 500;

export const CURRENCY_PAYMENT_PROVIDER: {
  [K in Currency]: PaymentProvider;
} = {
  NGN: PaymentProvider.Paystack,
  ZAR: PaymentProvider.Flutterwave,
  KES: PaymentProvider.Tingg,
};

export const PROVIDER_KEYS: {
  [K in Currency]: string;
} = {
  KES: FLUTTERWAVE_KE_API_KEY,
  ZAR: '',
  NGN: PAYSTACK_API_KEY,
};

export const CURRENCY_SYMBOL: {
  [K in Currency]: string;
} = {
  KES: 'Ksh',
  ZAR: 'R',
  NGN: '₦',
};

export const JQUERY_INLINE_SCRIPT_URL =
  'https://ajax.googleapis.com/ajax/libs/jquery/3.6.0/jquery.min.js';

export const PAYMENT_PROVIDERS_DATA = {
  [PaymentProvider.Paystack]: {
    icon: PaystackCircleLogo,
    name: 'Paystack',
    scriptUrl: PAYSTACK_INLINE_SCRIPT_URL,
  },
  [PaymentProvider.Flutterwave]: {
    icon: FlutterwaveCircleLogo,
    name: 'Flutterwave',
    scriptUrl: FLUTTERWAVE_INLINE_SCRIPT_URL,
  },
  [PaymentProvider.Tingg]: {
    icon: CellulantLogo,
    name: 'Tingg',
    scriptUrl: TINGG_INLINE_SCRIPT_URL,
  },
};
