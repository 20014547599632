import { ButtonVariant } from 'brass-ui-kit';
import { ButtonHTMLAttributes } from 'react';

export interface IObject<T = any> {
  [x: string]: T;
}

export type File = {
  key?: string;
  url: string;
  content_type?: string;
};

export type Embeds = Array<string>;

export type WithData<T> = {
  data: T;
};

export type Pagination = {
  current_page: number;
  per_page: number;
  total_pages: number;
  total?: number;
};

export type Payload<D, M = any> = {
  data: D;
  loading?: boolean;
  error?: string;
  pagination?: Pagination;
  meta?: M;
};

export type ButtonProps = {
  label?: string;
  size?: 'full' | 'default';
  props?: {
    disabled?: boolean;
    loading?: boolean;
    variant?: ButtonVariant;
  } & ButtonHTMLAttributes<any>;
};

export type FooterProps = {
  primary?: ButtonProps;
  secondary?: ButtonProps;
};

export type TFunc<V, R = void> = (x: V) => R;

export type Option<T = string> = { label: string; value: T };

export type Options<T = string> = Array<Option<T>>;

export type Currency = 'NGN' | 'ZAR' | 'KES';

export enum PaymentProvider {
  Paystack = 'paystack',
  Flutterwave = 'flutterwave',
  Tingg = 'tingg',
}

export enum DepositGateway {
  Paystack = 'Paystack',
  FlutterwaveKE = 'FlutterwaveKE',
  Tingg = 'Tingg',
}

export interface OnlinePaymentDetails {
  email: string;
  phone: string;
  name: string;
  firstname: string;
  lastname?: string;
  amount: number;
  currency: Currency;
  title: string;
  description: string;
  logo: string;
}

export type CurrencyData = {
  code: Currency;
  symbol: string;
};

export interface Amount {
  raw: string;
  formatted: string;
  formatted_display: string;
}

export type OtpChannel = 'authenticator_app' | 'email';
